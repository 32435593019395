import React from 'react';
import { Container, Row, Col, } from 'reactstrap';
import { Link } from 'gatsby';

import {toggleLang, getLang} from '../services/lang';
import Layout from '../components/Layout';
import RoomsSlider from '../components/Slices/RoomsSlider';
import * as TXT from '../res/strings';
import * as TXTEN from '../res/strings_en';
import * as LNK from '../res/links';
import { TEQUES1, TEQUES2, TEQUES3, TEQUES4, TEQUES5, TEQUES6, TEQUES7, TEQUES8, TEQUES9 } from '../res/images';

class Tequesquitengo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'ES',
    }

    this.toggleLanguage = this.toggleLanguage.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount(){
    if (getLang().language !== this.state.lang) {
      this.setState({lang: getLang().language});
      }
    this.updateWindowDimensions();
  }

  toggleLanguage(lang) {
    toggleLang(lang);
    if (lang !== this.state.lang) {
      this.setState({lang: lang});
    }
  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }

  render() {
    const RollList = [
      { img_src: TEQUES1, id: "roll-1" },
      { img_src: TEQUES2, id: "roll-2" },
      { img_src: TEQUES3, id: "roll-3" },
      { img_src: TEQUES4, id: "roll-4" },
      { img_src: TEQUES5, id: "roll-5" },
      { img_src: TEQUES6, id: "roll-6" },
      { img_src: TEQUES7, id: "roll-7" },
      { img_src: TEQUES8, id: "roll-8" },
      { img_src: TEQUES9, id: "roll-9" },
    ];
    const APP_LANG = this.state.lang;
    return (
      <Layout lang={this.state.lang} toggleLanguage={this.toggleLanguage}>
        <div className="section" id="start" style={{ marginTop: 100 }}>
					<Container>
          <Row>
              <Col className="text-center midTitle">
                <h1>
                  {APP_LANG === 'EN' ? TXTEN.TEQUES.TITLE1 : TXT.TEQUES.TITLE1}
                </h1>
              </Col>
            </Row>
            <RoomsSlider RollList = {RollList}/>
            <Row>
              <Col className="text-center">
                Images thanks to: https://es.wikipedia.org/wiki/Tequesquitengo
              </Col>
            </Row>
            <Row>
              <Col Col xs="12" md={{ size: 10, offset: 1 }} >
                <p>
                  {APP_LANG === 'EN' ? TXTEN.TEQUES.CONTENT_MAIN : TXT.TEQUES.CONTENT_MAIN}
                </p>
                <p>
                  {APP_LANG === 'EN' ? TXTEN.TEQUES.CONTENT_MAIN2 : TXT.TEQUES.CONTENT_MAIN2}
                </p>
                <p>
                  {APP_LANG === 'EN' ? TXTEN.TEQUES.CONTENT_MAIN3 : TXT.TEQUES.CONTENT_MAIN3}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md={{ size: 6, offset: 2 }} offset-md-3 className="has-text-second" >
                <ul>
                  <li>{APP_LANG === 'EN' ? TXTEN.TEQUES.CONTENT_LISTITEM1 : TXT.TEQUES.CONTENT_LISTITEM1}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.TEQUES.CONTENT_LISTITEM2 : TXT.TEQUES.CONTENT_LISTITEM2}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.TEQUES.CONTENT_LISTITEM3 : TXT.TEQUES.CONTENT_LISTITEM3}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.TEQUES.CONTENT_LISTITEM4 : TXT.TEQUES.CONTENT_LISTITEM4}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.TEQUES.CONTENT_LISTITEM5 : TXT.TEQUES.CONTENT_LISTITEM5}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.TEQUES.CONTENT_LISTITEM6 : TXT.TEQUES.CONTENT_LISTITEM6}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.TEQUES.CONTENT_LISTITEM7 : TXT.TEQUES.CONTENT_LISTITEM7}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.TEQUES.CONTENT_LISTITEM8 : TXT.TEQUES.CONTENT_LISTITEM8}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.TEQUES.CONTENT_LISTITEM9 : TXT.TEQUES.CONTENT_LISTITEM9}</li>
                </ul>
              </Col>
            </Row>
            <span className="separator_100" />
            <Row>
              <Col xs="12" md={{ size: 4, offset: 2 }}>
                <p>
                  {APP_LANG === 'EN' ? TXTEN.TEQUES.LINKS_TITLE : TXT.TEQUES.LINKS_TITLE}
                </p>
              </Col>
              <Col xs="12" md="6" className="has-text-second">
                <ul>
                  <li>
                  <a href={LNK.TEQUESLINKS.LINK1} target="_blank" rel="noopener noreferrer">
                    {LNK.TEQUESLINKS.LINK1}</a><br />
                  </li>
                  <li>
                    <a href={LNK.TEQUESLINKS.LINK2} target="_blank" rel="noopener noreferrer">
                    {LNK.TEQUESLINKS.LINK2}</a><br />
                  </li>
                  <li>
                  <a href={LNK.TEQUESLINKS.LINK3} target="_blank" rel="noopener noreferrer">
                    {LNK.TEQUESLINKS.LINK3}</a><br />
                  </li>
                  <li>
                  <a href={LNK.TEQUESLINKS.LINK4} target="_blank" rel="noopener noreferrer">
                    {LNK.TEQUESLINKS.LINK4}</a><br />
                  </li>
                  <li>
                  <a href={LNK.TEQUESLINKS.LINK5} target="_blank" rel="noopener noreferrer">
                    {LNK.TEQUESLINKS.LINK5}</a><br />
                  </li>
                </ul>
							</Col>
            </Row>
            <span className="separator_100" />
						<Row>
              <Col xl="12" className="text-center">
                <Link to="/reserve">
                  <div className="btnAction mt-auto">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.CTA_RESERVE : TXT.BUTTON.CTA_RESERVE}
                  </div>
                </Link>
              </Col>
            </Row>
						<Row>
              <Col xl="12" className="text-center">
                <Link to="/">
                  <div className="btnAction mt-auto">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.BTN_BACK_HOME : TXT.BUTTON.BTN_BACK_HOME}
                  </div>
                </Link>
              </Col>
            </Row>
            <Row />
					</Container>
				</div>
      </Layout>
    );
  }
}

export default Tequesquitengo;